<!-- <mat-card class="mb-3" *ngIf="available == true; else thenBlock">
  <agm-map [zoomControl]="true" [maxZoom]="25" [zoom]="3" [latitude]="lat" [longitude]="lng" [streetViewControl]="false"
    ter [keyboardShortcuts]="false" class="mapSize" [zoom]=zoom>
    <agm-marker *ngFor="let data of markers; let i = index" (markerClick)="clickedMarker(infowindow)"
      [iconUrl]="data.icon" [latitude]="data.lat" [longitude]="data.lng">
      <agm-info-window #infowindow>
        <strong>InfoWindow content</strong>
      </agm-info-window>
    </agm-marker>
    <agm-direction [renderOptions]="options" [origin]="origin" [destination]="destination">
    </agm-direction>
  </agm-map>
</mat-card> -->

<div class="parent" *ngIf="available == true; else thenBlock">
  <mat-card class="mb-3">
    <agm-map [styles]="Styles" [zoomControl]="true" [maxZoom]="25" [zoom]="3" [latitude]="lat" [longitude]="lng"
      [streetViewControl]="false" ter [keyboardShortcuts]="false" class="mapSize" [zoom]=zoom>
      <agm-marker *ngFor="let data of markers; let i = index" (markerClick)="clickedMarker(infowindow)"
        [iconUrl]="data.icon" [latitude]="data.lat" [longitude]="data.lng">
        <agm-info-window #infowindow>
          <div class="col-12 card l-bg-blue-dark">
            <div class="card-statistic-3 p-4">
              <h2>Driver Details</h2>
              <hr>
              <div class="col-md-12">
                <i class="fas fa-bus">
                  &nbsp; {{ vehicleNum }}
                </i>
              </div>
              <div class="col-md-12">
                <i class="fas fa-user">
                  &nbsp; {{ driverName }}
                </i>
              </div>
              <div class="col-md-12">
                <i class="fas fa-mobile">
                  &nbsp; <a style="color: white;" href="tel://+91{{ driverNum }}">{{ driverNum }}</a>
                </i>
              </div>
            </div>
          </div>
        </agm-info-window>
      </agm-marker>
      <agm-direction [renderOptions]="options" [origin]="origin" [destination]="destination">
      </agm-direction>
    </agm-map>
  </mat-card>
  <div class="row child">
    <div class="col-lg-12">
      <div class="card l-bg-blue-dark">
        <div class="card-statistic-3 p-4">
          <h2>Driver Details</h2>
          <hr>
          <div class="col-md-12">
            <i class="fas fa-bus">
              &nbsp; {{ vehicleNum }}
            </i>
          </div>
          <div class="col-md-12">
            <i class="fas fa-user">
              &nbsp; {{ driverName }}
            </i>
          </div>
          <div class="col-md-12">
            <i class="fas fa-mobile">
              &nbsp; <a style="color: white;" href="tel://+91{{ driverNum }}">{{ driverNum }}</a>
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #thenBlock>
  <div class="d-flex justify-content-center" *ngIf="available == false; else elseBlock">
    <div class="col-md-4">
      <lottie-player src="../../../../assets/close.json" background="transparent" speed="1" autoplay>
      </lottie-player>
      <div class="d-flex justify-content-center">
        <h2>Trip has been Closed...!</h2>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #elseBlock>
  <div class="d-flex justify-content-center">
    <div class="col-md-4">
      <lottie-player src="../../../../assets/close.json" background="transparent" speed="1" autoplay>
      </lottie-player>
      <div class="d-flex justify-content-center">
        <h2>No Data Found...!</h2>
      </div>
    </div>
  </div>
</ng-template>