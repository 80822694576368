import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  bookingDetails!: AngularFireObject<any>;
  vechileDetails!: AngularFireObject<any>;

  bookingAllDetails!: AngularFireObject<any>;
  vechileDetailsAll!:AngularFireObject<any>;
  

  constructor(private db: AngularFireDatabase) {}

  bookingDet(id: string) {
    this.bookingDetails = this.db.object('booking_details/' + id);
    return this.bookingDetails;
  }

  vechileDet(id: string) {
    this.vechileDetails = this.db.object('vehicle_details/' + id);
    return this.vechileDetails;
  }

  bookingAllDet() {
    this.bookingAllDetails = this.db.object('booking_details');
    return this.bookingAllDetails;
  }

  vechileDetAll() {
    this.vechileDetailsAll = this.db.object('vehicle_details');
    return this.vechileDetailsAll;
  }

  
}
