import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GoogleMapsAPIWrapper, MapsAPILoader, AgmMap, LatLngBounds, LatLngBoundsLiteral, MapTypeStyle } from '@agm/core';
import { AngularFireDatabase } from '@angular/fire/database';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  public travelMode: string = 'DRIVING'

  options = {
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#000080',
      strokeWeight: 6,
    }

  };

  /* Driver Detail variables */
  driverName: any;
  vehicleNum: any;
  driverNum: any;



  markers: any[] = [];
  polyline: any[] = [];
  position = {};
  start_end_mark: any[] = [];

  bookingId: any;
  vechileId: any;
  available: any = true;
  dataStatus: any = false;
  // initial center position for the map
  zoom = 8;
  lat: number = 13.067439;
  lng: number = 80.237617;

  originLat = 0.0;
  originLng = 0.0;
  destLat = 0.0;
  destLng = 0.0;

  origin = {}
  destination = {}

  obj = {}

  @ViewChild('AgmMap') agmMap: AgmMap;
  Styles: MapTypeStyle[] = [];

  constructor(private firebaseService: FirebaseService, private route: ActivatedRoute, public mapsAPILoader: MapsAPILoader, private db: AngularFireDatabase) {
    this.JSON_MAP_STYLES.forEach((style: any) => {
      this.Styles.push(style)
    })
  }

  public renderOptions = {
    suppressMarkers: false,
  }

  ngOnInit(): void {
    if (this.route.snapshot.params.id != null) {
      this.bookingId = this.route.snapshot.params.id;
      this.bookingDetails();
    } else {
      console.log("No Data Found");
    }
  }

  bookingDetails(): void {
    this.firebaseService.bookingDet(this.bookingId).valueChanges().subscribe(data => {
      console.log("firebase data ==========> ", data);
      if (data != null) {
        if (data.booking_acceptence_status == "trip_accepted") {
          this.available = true;
          this.vechileId = data.vechicle_id;
          this.destLat = data.drop_location_latitude;
          this.destLng = data.drop_location_longitude;
          this.driverName = data.driver_name;
          this.driverNum = data.driver_mobile_no;
          this.vehicleNum = data.vechicle_number;
          this.vechileDetails();
        } else {
          this.available = false;
        }
      } else {
        this.available = null;
      }
    })
  }

  vechileDetails(): void {
    this.firebaseService.vechileDet(this.vechileId).valueChanges().subscribe(data => {
      console.log("firebase data1 ==========> ", data);
      this.originLat = data.latitude;
      this.originLng = data.longitude;
      let ori = { lat: this.originLat, lng: this.originLng, label: 'Driver', icon: '../../../../assets/orig.png' };
      let des = { lat: this.destLat, lng: this.destLng, label: 'Destination', icon: '../../../../assets/dest.png' };
      let ori1 = [this.originLat, this.originLng];
      let des1 = [Number(this.destLat), Number(this.destLng)];
      this.lat = this.originLat;
      this.lng = this.originLng;
      this.origin = { lat: Number(this.originLat), lng: Number(this.originLng) };
      this.destination = { lat: Number(this.destLat), lng: Number(this.destLng) };
      this.markers = [];
      this.polyline = [];
      this.markers.push(ori, des);
      this.polyline.push(ori1, des1);
      this.start_end_mark.push(this.polyline[0]);
      this.start_end_mark.push(this.polyline[this.polyline.length - 1]);
    })
  }

  JSON_MAP_STYLES = [
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#444444"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "color": "#f2f2f2"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "lightness": 45
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#bae3f7"
        },
        {
          "visibility": "on"
        }
      ]
    }
  ]

}
function forEach(data: any, arg1: (value: any, key: any) => void) {
  throw new Error('Function not implemented.');
}
