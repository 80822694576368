<mat-card class="mb-3">
    <agm-map [zoomControl]="true" [maxZoom]="25" [zoom]="3" [latitude]="lat" [longitude]="lng" [streetViewControl]="false"ter [keyboardShortcuts]="false" class="mapSize" [zoom]=zoom>
      <agm-marker *ngFor="let data of markers; let i = index" [iconUrl]="data.icon"
        [latitude]="data.lat" [longitude]="data.lng">
      </agm-marker>
      <!-- <agm-polyline *ngFor="let coordinate of polyline; let i=index" (polyPathChange)="'driving'" [visible]="true" [zIndex]="10" [strokeWeight]="3" [strokeColor]="'#07b57a'">
        <agm-polyline-point *ngFor="let coordinate of polyline; let i=index" [latitude]="coordinate[0]"
          [longitude]="coordinate[1]">
  
        </agm-polyline-point>
      </agm-polyline> -->
      <!-- <agm-direction [renderOptions]="options" [origin]="origin" [destination]="destination">
      </agm-direction> -->
    </agm-map>
  </mat-card>