// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Production & Stage Firebase Config
  // firebase: {
  //   apiKey: "AIzaSyBxcIz-97H_qJboXKanX4EpFpQJFhFgIJA",
  //   authDomain: "myrmc-da878.firebaseapp.com",
  //   databaseURL: "https://myrmc-da878-default-rtdb.firebaseio.com",
  //   projectId: "myrmc-da878",
  //   storageBucket: "myrmc-da878.appspot.com",
  //   messagingSenderId: "467595445603",
  //   appId: "1:467595445603:web:091a86ce6de4a7f3363f56",
  //   measurementId: "G-EB2BMGJVXT"
  // }

  // Dev Firebase Config
  firebase: {
    apiKey: "AIzaSyBQMBN_Gc8vnAKPTPznVoAgAIGt923eusw",
    authDomain: "myrmc-dev.firebaseapp.com",
    databaseURL: "https://myrmc-dev-default-rtdb.firebaseio.com",
    projectId: "myrmc-dev",
    storageBucket: "myrmc-dev.appspot.com",
    messagingSenderId: "177810453815",
    appId: "1:177810453815:web:0d9a32bde9f1517effd6b2",
    measurementId: "G-Q9H94LTL65"
  }

 // Testing Firebase Config
  // firebase: {
  //   apiKey: "AIzaSyAbSxqQzMNraxAX7xf4QhuDFSu_1ICeX4s",
  // authDomain: "myrmc-np-test.firebaseapp.com",
  // databaseURL: "https://myrmc-np-test-default-rtdb.firebaseio.com",
  // projectId: "myrmc-np-test",
  // storageBucket: "myrmc-np-test.appspot.com",
  // messagingSenderId: "724678173599",
  // appId: "1:724678173599:web:069fb908909e2600c393aa",
  // measurementId: "G-EBLP7Q82XD"
  // }
};

//   //Dev testing
//   firebase: {
//     apiKey: "AIzaSyBQMBN_Gc8vnAKPTPznVoAgAIGt923eusw",
//     authDomain: "myrmc-dev.firebaseapp.com",
//     databaseURL: "https://myrmc-dev-default-rtdb.firebaseio.com",
//     projectId: "myrmc-dev",
//     storageBucket: "myrmc-dev.appspot.com",
//     messagingSenderId: "177810453815",
//     appId: "1:177810453815:web:0d9a32bde9f1517effd6b2",
//     measurementId: "G-Q9H94LTL65"

//   }
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
