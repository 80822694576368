import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { GoogleMapsAPIWrapper, MapsAPILoader, AgmMap, LatLngBounds, LatLngBoundsLiteral, MapTypeStyle } from '@agm/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public travelMode: string = 'DRIVING'

  options = {
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: '#000080',
      strokeWeight: 6,
    }

  };

  /* Driver Detail variables */
  driverName: any;
  vehicleNum: any;
  driverNum: any;



  markers: any[] = [];
  polyline: any[] = [];
  position = {};
  start_end_mark: any[] = [];
VechileData :any;
  bookingId: any;
  vechileId: any;
  available: any = true;
  dataStatus: any = false;
  // initial center position for the map
  zoom = 12;
  lat: number = 13.067439;
  lng: number = 80.237617;

  originLat = 0.0;
  originLng = 0.0;
  destLat = 0.0;
  destLng = 0.0;

  origin = {}
  destination = {}

  obj = {}

  @ViewChild('AgmMap') agmMap: AgmMap;
  Styles: MapTypeStyle[] = [];
  Length:any;
  VechileDataID:any;
icon:any;
bookingData:any
  bookingDataLength:any;
  vechileLng: any;
  vechileLat: any;
  constructor(private firebaseService: FirebaseService, private route: ActivatedRoute, public mapsAPILoader: MapsAPILoader, private db: AngularFireDatabase) { 
    this.JSON_MAP_STYLES.forEach((style: any) => {
      this.Styles.push(style)
    })
  }

  ngOnInit(): void {
    this.vechileDetailsAll();
    this.bookingDetails();
  }

  vechileDetailsAll(): void {
   
    this.firebaseService.vechileDetAll().valueChanges().subscribe(data => {
      //this.zoom =14;
     // console.log("firebase data1 ==========> ", data);
      let lastvalue = this.Length;
      this.Length = Object.keys(data).length;
      if(lastvalue < this.Length){
      this.playsound()
    }
    this.VechileDataID = Object.values(data);
    this.VechileData =Object.keys(data);
      this.icon = 'https://myrmc-stores.s3.ap-south-1.amazonaws.com/mapicon/maptm.png'
    
      for(let i=0; i<this.Length;i++){
        this.originLat = this.VechileDataID[i].latitude;
        this.originLng = this.VechileDataID[i].longitude; 
      }
      


      let ori = { lat: this.originLat, lng: this.originLng, label: 'Driver', icon: '../../../../assets/orig.png' };
      let des = { lat: this.destLat, lng: this.destLng, label: 'Destination', icon: '../../../../assets/dest.png' };
      let ori1 = [this.originLat, this.originLng];
      let des1 = [Number(this.destLat), Number(this.destLng)];
      this.lat = this.originLat;
      this.lng = this.VechileDataID.longitude;
      this.origin = { lat: Number(this.originLat), lng: Number(this.originLng) };
      this.destination = { lat: Number(this.destLat), lng: Number(this.destLng) };
      this.markers = [];
      this.polyline = [];
      this.markers.push(ori);
      this.polyline.push(ori1);
      this.start_end_mark.push(this.polyline[0]);
      this.start_end_mark.push(this.polyline[this.polyline.length - 1]);
      
    })
  }

  playsound(){
    let audio = new Audio();
    audio.src = "../../../assets/Pikachu.mp3";
    audio.load();
    audio.play();
  }

  bookingDetails(): void {
    this.firebaseService.bookingAllDet().valueChanges().subscribe(data => {
      if (data != null) {
        this.bookingDataLength = Object.keys(data).length
          this.bookingData = Object.values(data)
          for(let i=0;i<this.bookingDataLength;i++){
            if(this.bookingData[i].vechicle_id){
           this.bookingDetailsSingle(this.bookingData[i].booking_id)
            }
           
          }
          
    

    }
  })
}

bookingDetailsSingle(id:any): void {
  this.firebaseService.bookingDet(id).valueChanges().subscribe(data => {
     if(data != null){
     this.vechileDetailsSingle(data.vechicle_id)
    }
    
})
}

vechileDetailsSingle(id:any): void {
  this.firebaseService.vechileDet(id).valueChanges().subscribe(data => {
      if(data != null){
    this.vechileLat = data.latitude;
    this.vechileLng = data.longitude 
    this.markers.push({lat:this.vechileLat,lng:this.vechileLng})
  }
  })
}

  JSON_MAP_STYLES = [
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",  
      "stylers": [
        {
          "color": "#444444"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "color": "#f2f2f2"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "lightness": 45
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
        {
          "color": "#bae3f7"
        },
        {
          "visibility": "on"
        }
      ]
    }
  ]

}
