import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {
  zoom = 8;
  lat: number = 13.067439;
  lng: number = 80.237617;

  markers;
  ori: any;
  constructor(private db: AngularFireDatabase) { }

  ngOnInit(): void {
    this.bookingAllDetails();
  }

  bookingAllDetails(): void {
    var mk = [];
    var vd = this.db.database.ref("vehicle_details/");
    vd.orderByChild("vehicle_current_location").on("child_added", function(data){

      mk.push({lat: data.val().vehicle_current_location.latitude, lng: data.val().vehicle_current_location.longitude, label: 'Driver', icon: '../../../../assets/dest.png'});
    })
    this.markers = mk;
    console.log("vehicle count =======> ", this.markers.length)
    console.log("array", this.markers);

    // this.db.object("vehicle_details").valueChanges().subscribe(data => {
    //   console.log("firebase check =====> ", data);
    //   mk.push({lat: data.val().vehicle_current_location.latitude, lng: data.val().vehicle_current_location.longitude, label: 'Driver', icon: '../../../../assets/dest.png'});
    // })

    // this.db.object("vehicle_details").valueChanges().pipe()
  }

}
