<mat-card class="mb-3">
    <agm-map [styles]="Styles" [zoomControl]="true" [maxZoom]="25"  [zoom]="3" [latitude]="lat" [longitude]="lng"
      [streetViewControl]="false" ter [keyboardShortcuts]="false" class="mapSize" [zoom]=zoom>
      <agm-marker *ngFor="let data of VechileDataID; let i = index" 
        [iconUrl]="icon"  [latitude]="data.latitude" [longitude]="data.longitude"  [markerClickable]="true"
        (markerClick)="bookingDetails(data)">
        
        <agm-info-window #infoWindow>
            {{ data.latitude}},{{data.longitude }}
      </agm-info-window>
        </agm-marker>
      
        </agm-map>

        </mat-card>
